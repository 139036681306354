import { SingleSignalBookmarkStateModel } from '../reducer/single-signal-bookmark.state';

export class SetSelectedSignalBookmark {
    static readonly type = '[SignalBookmarks] Set Selected Signal Bookmark';
    constructor(public selectedBookmark: SingleSignalBookmarkStateModel | null) {}
}

export class SetUserSignalBookmarks {
    static readonly type = '[SignalBookmarks] Set User Signal Bookmarks';
    constructor(public userBookmarks: SingleSignalBookmarkStateModel[]) {}
}

export class AddUserSignalBookmark {
    static readonly type = '[SignalBookmarks] Add User Signal Bookmark';
    constructor(public bookmark: SingleSignalBookmarkStateModel) {}
}

export class RemoveUserSignalBookmark {
    static readonly type = '[SignalBookmarks] Remove User Signal Bookmark';
    constructor(public bookmarkId: string) {}
}

export class ResetSignalBookmarkState {
    static readonly type = '[SignalBookmarks] Reset Signal Bookmark State';
}

export class UpdateUserSignalBookmark {
    static readonly type = '[SignalBookmarks] Update User Signal Bookmark';
    constructor(public bookmark: SingleSignalBookmarkStateModel) {}
}
