import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaPlusHttpService } from './http/eva-http.service';
import { map } from 'rxjs/operators';

export interface IDriverJourneysRequest {
    name: string;
    driverId: string;
    startTime: string;
    endTime: string;
}

export interface IDriver {
    id: string;
    name: string;
}

export interface IDriverJourney {
    driverId: string;
    routeCode: string;
    unit: string;
    startTime: string;
    endTime: string;
}

const baseUrl = '/EvaPlusRest/drivermanagement/';

export interface IDriverJourneyData {
    pBarText: string;
    pCaption: string;
    pClass: string;
    pComp: number;
    pDepend: string;
    pGroup: number;
    pLink: string;
    pMile: number;
    pName: string;
    pNotes: string;
    pOpen: number;
    pParent: number;
    pRes: string;
    pStart: string;
    pEnd: string;
}

@Injectable({ providedIn: 'root' })
export class DriverManagementService {
    constructor(private http: EvaPlusHttpService) {}

    saveDriverOrManager(dataLoad: DMDsObject): Observable<any> {
        return this.http.post(baseUrl + 'saveDriverOrManager', dataLoad);
    }

    saveEditedDriverOrManager(dataLoad: DMDsObject): Observable<any> {
        return this.http.post(baseUrl + 'saveEditedDriverOrManager', dataLoad);
    }

    deleteDriverOrManager(dataLoad: DMDsObject): Observable<any> {
        return this.http.post(baseUrl + 'deleteDriverOrManager', dataLoad);
    }

    getAllDriversAndManaers(): Observable<any> {
        return this.http.get(baseUrl + 'getAllUsersOrManagers');
    }

    getAllManagers(): Observable<DMDsObject[]> {
        return this.http.get(baseUrl + 'getAllManagers');
    }

    getAllDrivers(): Observable<DMDsObject[]> {
        return this.http.get(baseUrl + 'getAllDrivers');
    }

    /**
     * Returns all drivers which are in some of the teams of the driver manager
     * @param managerName: string
     */
    getAllDriversForManager(managerName: string): Observable<IDriver[]> {
        const queryParams = {
            managerName,
        };
        return this.http.get(baseUrl + 'getAllDriversForManager', { params: queryParams }).pipe(
            map((allDrivers: string[]) =>
                allDrivers.map((driver) => {
                    // Split string by '[' - e.g. John Doe[5072]
                    const driverSplit = driver.split('[');
                    const driverName = driverSplit[0];
                    const driverId = driverSplit[1].split(']')[0];

                    // Returns IDriver
                    return {
                        id: driverId,
                        name: driverName,
                    };
                })
            )
        );
    }

    getTeams(): Observable<DriverTeamObject[]> {
        return this.http.get(baseUrl + 'getTeams');
    }

    saveTeam(dataLoad: DriverTeamObject): Observable<any> {
        return this.http.post(baseUrl + 'saveTeam', dataLoad);
    }

    getTeam(name: string): Observable<DriverTeamObject> {
        return this.http.get(baseUrl + 'getTeam', { params: { name } });
    }

    getDriverDashboard(
        name: string,
        type: string,
        dateStart: string | Date,
        dateStop: string | Date
    ): Observable<IDriverJourneyData[]> {
        return this.http.get(
            baseUrl +
                'getDriverDashboard?name=' +
                name +
                '&type=' +
                type +
                '&dateStart=' +
                dateStart +
                '&dateStop=' +
                dateStop
        );
    }

    /**
     * Returns all journeys by the driver's id which are in the date range scope
     * @param request: IDriverJourneysRequest
     */
    getDriverJourneys(request: IDriverJourneysRequest): Observable<IDriverJourney[]> {
        const url: string = `${baseUrl}getDriverJourneys`;
        return this.http.get(url, { params: request });
    }

    getAllSignals(): Observable<any> {
        return this.http.get(baseUrl + 'getListOfSignals');
    }

    getListOfDriverIdSignals(): Observable<any> {
        return this.http.get(baseUrl + 'getListOfDriverIdSignals');
    }

    getListOfRouteCodes(): Observable<any> {
        return this.http.get(baseUrl + 'getListOfRouteCodes');
    }

    saveDriverIdCodes(driverIdCodes: string[]) {
        return this.http.post(baseUrl + 'saveDriverIdList', driverIdCodes);
    }

    saveRouteCodes(driverIdCodes: string[]) {
        return this.http.post(baseUrl + 'saveRouteIdList', driverIdCodes);
    }

    deleteTeam(team: string): Observable<any> {
        return this.http.post(baseUrl + 'deleteTeam', { name: team });
    }

    getListOfUsers(): Observable<string[]> {
        return this.http.get(baseUrl + 'getListOfUsers');
    }

    getListOfDrivers(): Observable<any> {
        return this.http.get(baseUrl + 'getListOfDrivers');
    }

    saveBookmark(content: any): Observable<any> {
        return this.http.post(baseUrl + 'saveBookmark', content);
    }

    getLastBookmarkTest(): Observable<any> {
        return this.http.get(baseUrl + 'getLastBookmark');
    }

    getAllBookmarks(user: string): Observable<any> {
        return this.http.post(baseUrl + 'getAllBookmarks', user);
    }

    deleteBookmark(id: string): Observable<any> {
        return this.http.delete(baseUrl + 'deleteBookmark/' + id);
    }

    saveBookmarkOverwrite(content: any): Observable<any> {
        return this.http.post(baseUrl + 'saveBookmarkOverwrite', content);
    }

    uploadTeamCSV(file: File): Observable<any> {
        let uriString = baseUrl + 'uploadTeamCSV';

        let formData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post(uriString, formData, {
            disableAutoContentTypeHader: true,
        });
    }
}

export class DMDsObject {
    objectId: string;
    type: string;
    id: string;
    name: string;
    comment: string;
    user: string;
    newUser: boolean;
}

export class DriverTeamObject {
    name: string;
    _id: string;
    driverManager: string;
    drivers: string[];
    routes: string[];
    comment: string;
}
