import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaPlusHttpService } from './http/eva-http.service';
import { map } from 'rxjs/operators';
import { Announcement, AnnouncementType, IAnnouncement } from '../models/announcement.model';

export interface IDashboardsConfiguration {
    // basic
    dashboardEnabled: boolean;
    signalDashboardEnabled: boolean;
    eventDashboardEnabled: boolean;
    telocFilesEnabled: boolean;
    telocFilesOldEnabled: boolean;
    telocFilesNewEnabled: boolean;
    telocFilesByDriverIdEnabled: boolean;
    telocFilesByRouteCodeEnabled: boolean;
    telocFilesByEventEnabled: boolean;
    reportsEnabled: boolean;
    notificationsEnabled: boolean;
    userMonitoringEnabled: boolean;
    coverageNotificationsEnabled: boolean;
    liveModuleEnabled: boolean;
    telocFileStatusDashboardEnabled: boolean;
    recentEventsDashboardEnabled: boolean;
    eventAnalyticsDashboardEnabled: boolean;
    signalAnalyticsDashboardEnabled: boolean;

    // modules
    fleetLocationEnabled: boolean;
    fleetManagementEnabled: boolean;
    baliseMonitoringEnabled: boolean;
    brushDashboardEnabled: boolean;
    deviceManagerTelocEnabled: boolean;
    dataExplorerEnabled: boolean;
    dataCoverageEnabled: boolean;
    userDashboardEnabled: boolean;
    journeyAnalyticsEnabled: boolean;
    searchModuleEnabled: boolean;
    eventGeneratorEnabled: boolean;
    driverManagementEnabled: boolean;
    tel2disEnabled: boolean;
    tableauModuleEnabled: boolean;
    iomsModuleEnabled: boolean;
    dataManagementDashboardEnabled: boolean;
    dasSystemEnabled: boolean;

    // settings
    errorCodeSettingsModuleEnabled: boolean;
    allCodeSettingsModuleEnabled: boolean;
    signalSettingsEnabled: boolean;
    vehicleInsights: boolean;
    processorSettingsEnabled: boolean;
    userIdentityMgmtEnabled: boolean;
    notificationModuleEnabled: boolean;
    tenantManagementEnabled: boolean;
    useConfigDb: boolean;

    // default map coordinates
    defaultCoordinates: string;

    timeZone: string;
    eventLimit: number;
    errorLogLimit: number;
    telocFilesPageSize: number;
    ftpPath: string;

    // fleet location map boundsChange event params
    upperLeftLat: string;
    upperLeftLon: string;
    lowerRightLat: string;
    lowerRightLon: string;
}

export interface ITimeZoneInfo {
    selectedTimeZoneId: string;
    timeZoneIds: string[];
}

export interface ISupportEmail {
    supportEmail: string;
}

export interface ITimeZoneId {
    timeZoneId: string;
}

export interface IConfigutarionData {
    gpsSignalId: string;
    selectedDistanceUnitTeloc: string;
    selectedDistanceUnitETCS: string;
    selectedDistanceUnitView: string;
}

export interface ICreateAnnouncementRequest {
    type: AnnouncementType;
    title: string;
    text: string;
}

const baseUrl = '/EvaPlusRest/configuration/';

@Injectable({ providedIn: 'root' })
export class DashboardsConfigurationService {
    constructor(private http: EvaPlusHttpService) {}

    getDashboards(): Observable<IDashboardsConfiguration> {
        return this.http.get(baseUrl + 'getDashboards');
    }

    /**
     * Returns a list with all announcements
     */
    getAnnouncements(): Observable<Announcement[]> {
        return this.http
            .get(`${baseUrl}getAnnouncements`)
            .pipe(
                map((announcements: IAnnouncement[]) =>
                    announcements.map((announcement) => Announcement.from(announcement))
                )
            );
    }

    /**
     * Returns all announcements which are not seen by the logged-in user
     * @param username - Username of the logged-in user
     */
    getUserAnnouncements(username: string): Observable<Announcement[]> {
        return this.http
            .get(`${baseUrl}getUserAnnouncements/${username}`)
            .pipe(
                map((announcements: IAnnouncement[]) =>
                    announcements.map((announcement: IAnnouncement) => Announcement.from(announcement))
                )
            );
    }

    /**
     * Creates a new announcement
     * @param request - Create announcement request
     */
    createAnnouncement(request: ICreateAnnouncementRequest): Observable<Announcement> {
        return this.http
            .post(`${baseUrl}saveAnnouncement`, request)
            .pipe(map((announcement: IAnnouncement) => Announcement.from(announcement)));
    }

    /**
     * Updates an announcement
     * @param request - Announcement which is updated
     */
    updateAnnouncement(request: IAnnouncement): Observable<Announcement> {
        return this.http
            .post(`${baseUrl}updateAnnouncement`, request)
            .pipe(map((announcement: IAnnouncement) => Announcement.from(announcement)));
    }

    /**
     * Deletes an announcement by the id
     * @param id - Id of the announcement
     */
    deleteAnnouncement(id: string): Observable<string> {
        return this.http.delete(`${baseUrl}deleteAnnouncement/${id}`);
    }

    /**
     * Sends a request to the server to set announcement to be seen by the logged-in user
     * @param id - Id of the announcement
     * @param username - Username of the logged-in user
     */
    seenAnnouncement(id: string, username: string): Observable<any> {
        return this.http.post(`${baseUrl}seenAnnouncement/${id}/${username}`, {});
    }

    getVehicleTypes(): Observable<string[]> {
        return this.http.get(baseUrl + 'getVehicleTypes');
    }

    getConfigurationDataForVehicleId(vehicleId: string): Observable<IConfigutarionData> {
        return this.http.get(baseUrl + 'getConfigurationDataForVehicleId', {
            params: { vehicleId },
        });
    }

    getConfigurationDataForVehicleType(vehicleType: string): Observable<IConfigutarionData> {
        return this.http.get(baseUrl + 'getConfigurationDataForVehicleType', {
            params: { vehicleType },
        });
    }

    updateConfigurationDataForVehicleType(
        vehicleType: string,
        distanceUnitTeloc: string,
        distanceUnitETCS: string,
        distanceUnitView: string,
        gpsSignalId: string
    ) {
        let content = JSON.stringify({
            vehicleType: vehicleType,
            distanceUnitTeloc: distanceUnitTeloc,
            distanceUnitETCS: distanceUnitETCS,
            distanceUnitView: distanceUnitView,
            gpsSignalId: gpsSignalId,
        });

        return this.http.post(baseUrl + 'updateConfigurationDataForVehicleType', content);
    }

    getTimeZoneInfo(): Observable<ITimeZoneInfo> {
        return this.http.get(baseUrl + 'getTimeZoneInfo');
    }

    saveTimeZoneToDb(timezone: string): Observable<any> {
        let payload = JSON.stringify({ timezone: timezone });
        return this.http.post(baseUrl + 'timeZoneToDb', payload);
    }

    getTimezoneId(): Observable<ITimeZoneId> {
        return this.http.get(baseUrl + 'getTimezoneId');
    }

    updateTimeZone(timeZone: string) {
        let content = JSON.stringify({ timeZone: timeZone });
        return this.http.post(baseUrl + 'updateTimeZone', content);
    }

    getSupportEmail(): Observable<ISupportEmail> {
        return this.http.get(baseUrl + 'getSupportEmail');
    }
}
