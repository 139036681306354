import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TENANT } from '../config/api.config';
import { EvaPlusHttpService } from './http/eva-http.service';

export interface IChartDataComplete {
    chartDataAnalog: IChartDataFull[];
    chartDataAnalog2: IChartDataFull[];
    chartDataAnalogFull: IChartDataFull[];
    chartDataAnalog2Full: IChartDataFull[];
    chartDataDigital: IChartDataFull[];
    chartDataOther: IChartDataFull[];
    chartDataEtcs: IChartDataFull[];
    chartDataEvent: IChartDataFull[];
    chartDataGaps: IChartDataFull[];
    tableColumns: any[];
    tableData: any[];
    tableDataRaw: any[];
    filterFromTime: string;
    filterToTime: string;
    columns: string[];
    configurationData: IConfiguration[];
    qrPoc: boolean;
}

export interface IChartDataFull {
    chartdata: IChartData[];
    chartoptions: any;
    chartcolors: any[];
    chartwidth: number;
    chartheight: number;
    timefrom: string;
    timeto: string;
    chartid: string;
}

export interface IChartData {
    label: string;
    signal: string;
    yAxisID: string;
    data: IChartDataItem[];
    labels: string[];
}

export interface IChartDataItem {
    y: number;
    x: string;
}

export interface ICriteriaData {
    vehicleIds: ICriteriaDataItem[];
    memoryTypes: ICriteriaDataItem[];
    signalsAnalog: string[];
    signalsDigital: string[];
    signalsOther: string[];
    signalsEtcs: string[];
    signalsEvent: string[];
    signalsStanding: string[];
    signalsFull: ISignalItem[];
    skippedMemoryFiles: string[];
    memorySizeLimit: string;
}

export type SignalType =
    | 'SIGNAL_TYPE_DIGITAL'
    | 'SIGNAL_TYPE_ANALOG'
    | 'SIGNAL_TYPE_ETCS'
    | 'SIGNAL_TYPE_EVENT'
    | 'SIGNAL_TYPE_OTHER';

export interface ISignalItem {
    signalid: string;
    signalname: string;
    signaltype: SignalType;
    signalorigin: string;
    signalmemory: string;
    serialnumber: string;
    confandissue: string;
    starttime: number;
    endtime: number;
    fleetid: string;
}

export interface ISignalsChanged {
    signals: Map<string, string[]>;
    events: string[];
    etcsSignals: string[];
}

export interface ISignalIden {
    name: string;
    memory: string;
}

export interface ICriteriaDataItem {
    value: string;
    viewValue: string;
}

export interface ITelocData {
    telocfile: string;
    key: string;
    telocfilefull: string;
    vehicleid: string;
    recorderid: string;
    time: number;
    timeStr: string;
    confIssue: string;
}

export interface ITelocFileStatus {
    telocFileName: string;
    recorderId: string;
    startProcessingTime: number;
    fileUploadDuration: number;
    dataTo_millis: number;
    dataFrom_millis: number;
    progressPercentage: number;
    fileSize: number;
    vehicleId: string;
    status: string;
}

export interface IEventData {
    telocfile: string;
    key: string;
    telocfilefull: string;
    vehicleId: string;
    timeStr: string;
    eventDesc: string;
    driverId: string;
    routeCode: string;
    // this field wiil be used for milliseconds from database
    timeMilisString: string;
    confIssue: string;
}

export interface IBaliseData {
    balisegroup: String;
    balise: String;
    error: String;
    classification: String;
    location: String;
    density: String;
    status: String;
    replaced: String;
    lastread: String;
    gpslon: String;
    gpslat: String;
}

export interface IBaliseError {
    vehicle_id: String;
    train_type: String;
    speed: String;
    balise_error: String;
    location: String;
    classification: String;
    lastread: String;
    gpslon: String;
    gpslat: String;
}

export interface IBaliseDetailsData {
    balise: String;
    status: String;
    description: String;
}

export interface ILastTs {
    timestamp: number;
}

export interface IConfiguration {
    vehicleId: string;
    serialNumber: string;
    configIssue: string;
    telocMemory: string;
    telocFile: string;
    wheelDiameter: string;
    startTime: string;
    endTime: string;
}

export interface IVehicleConfigurationParams {
    vehicle: string;
    recorder: string;
    etcsType: string;
    memory: string;
}

export interface IPreviewChartParams {
    timeFrom: string;
    timeTo: string;
}

export interface IBookmarkMarkerData {
    markerSignalTextLeft: string[];
    markerSignalTextRight: string[];
    markerSignalTextDeviatian: string[];
    timeMin: any;
}

export interface ISelectedSignals {
    analog: string[];
    digital: string[];
    other: string[];
    standing: string[];
    etcs: string[];
    event: string[];
}

const baseSAUrl = '/EvaPlusRest/signalanalytics/';
const baseTelocUrl = '/EvaPlusRest/telocfile/';

@Injectable({ providedIn: 'root' })
export class SignalService {
    constructor(private _platformLocation: PlatformLocation, private http: EvaPlusHttpService) {}

    signalsQuery(
        vehicleId: string,
        memoryType: string,
        timefrom: string,
        timeto: string,
        signals: string[]
    ): Observable<any> {
        var uriString =
            '/EvaPlusRest/chartdata/getSignalsQuery?tenant=' +
            TENANT +
            '&vehicleId=' +
            vehicleId +
            '&memoryType=' +
            memoryType +
            '&from=' +
            timefrom +
            '&to=' +
            timeto;
        signals.forEach((element) => {
            uriString += '&signals=' + element;
        });

        return this.http.get('/EvaPlusRest/chartdata/getSignalsQuery', {
            tenant: TENANT,
            vehicleId,
            memoryType,
            from: timefrom,
            to: timeto,
        });
    }

    signalsQueryFull(
        vehicleId: string,
        memoryType: string,
        timefrom: string,
        timeto: string,
        signalsAnalog: string[],
        signalsDigital: string[]
    ): Observable<IChartDataComplete> {
        /* var uriString = '/EvaPlusRest/signalanalytics/getSignalsQueryFull?tenant=' + TENANT +'&vehicleId=' + vehicleId +
                    '&memoryType=' + memoryType + '&from=' + timefrom + '&to=' + timeto;

    signalsAnalog.forEach(element => {
      uriString += '&signalsAnalog=' + element;
    });

    signalsDigital.forEach(element => {
      uriString += '&signalsDigital=' + element;
    }); */

        return this.http.get(baseSAUrl + 'getSignalsQueryFull', {
            tenant: TENANT,
            vehicleId,
            memoryType,
            from: timefrom,
            to: timeto,
            signalsAnalog: signalsAnalog,
            signalsDigital: signalsDigital,
        });
    }

    criteriaQuery(): Observable<any> {
        return this.http.get(baseSAUrl + 'getSignalsCriteria', { tenant: TENANT });
    }

    telocByDriver(driverId: string, timefrom: string, timeto: string, vehicleId: string): Observable<any> {
        const queryParams = {
            tenant: TENANT,
            from: timefrom,
            to: timeto,
            driverId,
            vehicleId,
        };

        return this.http.get(baseTelocUrl + 'getTelocForDriver', { params: queryParams });
    }

    getDriverList(): Observable<any> {
        return this.http.get(baseTelocUrl + 'getDriverList', {
            tenant: TENANT,
        });
    }

    telocByRoute(routeCode: string, timefrom: string, timeto: string, vehicleId: string): Observable<any> {
        const queryParams = {
            tenant: TENANT,
            from: timefrom,
            to: timeto,
            routeCode,
            vehicleId,
        };

        return this.http.get(baseTelocUrl + 'getTelocForRoute', { params: queryParams });
    }

    getRouteList(): Observable<any> {
        const uriString = 'getRouteList?tenant=' + TENANT;
        return this.http.get(baseTelocUrl + uriString);
    }

    telocByEvent(eventType: string, timefrom: string, timeto: string, vehicleId: string): Observable<any> {
        const queryParams = {
            tenant: TENANT,
            from: timefrom,
            to: timeto,
            eventType,
            vehicleId,
        };

        return this.http.get(baseTelocUrl + 'getTelocForEvent', { params: queryParams });
    }

    getEventList(): Observable<any> {
        //var uriString = '/EvaPlusRest/telocfile/getEventList?tenant=' + TENANT;
        return this.http.get(baseTelocUrl + 'getEventList');
    }

    getTelocFile(telocFile: string, confIssue: string, vehicleId: string): Observable<any> {
        //var uriString = '/EvaPlusRest/telocfile/getFileBin?filepath=' + telocFile+'&confIssue=' + confIssue+'&vehicleId=' + vehicleId;
        return this.http.get(baseTelocUrl + 'getFileBin', {
            filepath: telocFile,
            confIssue: confIssue,
            vehicleId: vehicleId,
        });
    }

    // read config file for ag grid
    getJourneyAnalyticsGridConfig(): Observable<any> {
        return this.http.getAsset('assets/configs/journey-analytics-config.json');
    }
}
