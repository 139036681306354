import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { DriverManagementService } from '../../../services/driver-management.service';
import { AddUserSignalBookmark } from '../../store/signal-analytics/action/signal-bookmarks.actions';
import { SingleSignalBookmarkStateModel } from '../../store/signal-analytics/reducer/single-signal-bookmark.state';

@Component({
    selector: 'hr-bookmarks-dialog.component',
    templateUrl: 'hr-bookmarks-dialog.component.html',
})
export class DialogBookmark {
    chartOptions: any;

    constructor(
        public dialogRef: MatDialogRef<DialogBookmark>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private store: Store,
        public dialog: MatDialog,
        private _driverManagementService: DriverManagementService
    ) {}

    onNoClick(): void {
        this.dialogRef.close({ data: 'canceled' });
    }

    save() {
        this._driverManagementService.saveBookmark(this.data).subscribe((response: any) => {
            let bookmarkData = {
                id: response['bookmarkId'], //because API does not return id of bookmark on save
                bookmarkName: this.data.comment,
                isPrivate: this.data.isPrivate,
                user: this.data.user,
                gpsLocationLeft: this.data.gpsLocationLeft,
                gpsLocationRight: this.data.gpsLocationRight,
                itemsRequireMatchAnalog: this.data.itemsRequireMatchAnalog,
                itemsRequireMatchDigital: this.data.itemsRequireMatchDigital,
                itemsRequireMatchEtcs: this.data.itemsRequireMatchEtcs,
                itemsRequireMatchEvent: this.data.itemsRequireMatchEvent,
                itemsRequireMatchOther: this.data.itemsRequireMatchOther,
                selectedRecorderValue: this.data.selectedRecorderValue,
                template: this.data.template,
                timefromSignals: this.data.timefromSignals,
                timetoSignals: this.data.timetoSignals,
                timeFrom: new Date(this.data.timeFrom),
                timeTo: new Date(this.data.timeTo),
                vehicleId: this.data.vehicle_id,
                chartData: this.data.chartData ? JSON.parse(this.data.chartData) : null,
                analogCanvas: this.data.analog_canvas ? JSON.parse(this.data.analog_canvas) : null,
                analogCanvas2: this.data.analog_canvas2 ? JSON.parse(this.data.analog_canvas2) : null,
                etcsCanvas: this.data.etcs_canvas ? JSON.parse(this.data.etcs_canvas) : null,
                digitalCanvas: this.data.digital_canvas ? JSON.parse(this.data.digital_canvas) : null,
                eventCanvas: this.data.event_canvas ? JSON.parse(this.data.event_canvas) : null,
                previewChartData: this.data.chartOptionsPrewiew ? JSON.parse(this.data.chartOptionsPrewiew) : null,
            } as SingleSignalBookmarkStateModel;

            this.store.dispatch(new AddUserSignalBookmark(bookmarkData));
            this.dialogRef.close({ data: 'confirmed' });
        });
    }

    getCurrentChartOptions(co: any) {
        this.chartOptions = co;
    }
}

export interface DialogData {
    width: string;
    height: string;
    data: { chartData: any };
    comment: string;
}
