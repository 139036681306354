import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import {
    SetSelectedSignalBookmark,
    SetUserSignalBookmarks,
    AddUserSignalBookmark,
    RemoveUserSignalBookmark,
    ResetSignalBookmarkState,
    UpdateUserSignalBookmark,
} from '../action/signal-bookmarks.actions';
import { SingleSignalBookmarkStateModel } from './single-signal-bookmark.state';

export interface SignalBookmarksStateModel {
    selectedBookmark: SingleSignalBookmarkStateModel | null;
    userBookmarks: SingleSignalBookmarkStateModel[];
}

const defaultState = {
    selectedBookmark: null,
    userBookmarks: [],
};

@Injectable()
@State<SignalBookmarksStateModel>({
    name: 'signalBookmarks',
    defaults: defaultState,
})
export class SignalBookmarksState {
    @Action(SetSelectedSignalBookmark)
    setSelectedBookmark(ctx: StateContext<SignalBookmarksStateModel>, action: SetSelectedSignalBookmark) {
        ctx.patchState({ selectedBookmark: action.selectedBookmark });
    }

    @Action(SetUserSignalBookmarks)
    setUserBookmarks(ctx: StateContext<SignalBookmarksStateModel>, action: SetUserSignalBookmarks) {
        ctx.patchState({ userBookmarks: action.userBookmarks });
    }

    @Action(AddUserSignalBookmark)
    addUserBookmark(ctx: StateContext<SignalBookmarksStateModel>, action: AddUserSignalBookmark) {
        ctx.patchState({ userBookmarks: [...ctx.getState().userBookmarks, action.bookmark] });
    }

    @Action(RemoveUserSignalBookmark)
    removeUserBookmark(ctx: StateContext<SignalBookmarksStateModel>, action: RemoveUserSignalBookmark) {
        ctx.patchState({
            userBookmarks: ctx.getState().userBookmarks.filter((bookmark) => bookmark.id !== action.bookmarkId),
        });
    }

    @Action(ResetSignalBookmarkState)
    resetSignalBookmarkState(ctx: StateContext<SignalBookmarksStateModel>, action: ResetSignalBookmarkState) {
        ctx.setState(defaultState);
    }

    @Action(UpdateUserSignalBookmark)
    updateUserBookmark(ctx: StateContext<SignalBookmarksStateModel>, action: UpdateUserSignalBookmark) {
        const state = ctx.getState();
        const updatedBookmarks = state.userBookmarks.map((bookmark) =>
            bookmark.id === action.bookmark.id ? { ...action.bookmark, bookmarkName: bookmark.bookmarkName } : bookmark
        );
        ctx.patchState({ userBookmarks: updatedBookmarks });
    }
}
